import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { theme } from 'theme';
import { PageContextType } from 'types';

import Layout from 'components/templates/layout';
import CaseStudies, { CaseStudiesType } from 'components/organisms/case-studies';
import HeadTitleDescriptionImage, {
  BannerPrimaryType,
} from 'components/organisms/head-title-description-image/head-title-description-image';
import IndustriesServices, {
  IndustriesServicesType,
} from 'components/organisms/industries-services';
import ManagerSection, { ManagerSectionType } from 'components/organisms/manager-section';
import OurMissionSection, {
  TextBannerWithImageType,
} from 'components/organisms/our-mission-section';
import TextBannerCardSection, {
  TextBannerCardType,
} from 'components/organisms/text-banner-card-section';
import BannerCard from 'components/molecules/banner-card';
import LetsTalkBanner, { LetsTalkBannerType } from 'components/molecules/lets-talk-banner';
import Breadcrumb from 'components/atoms/breadcrumb/breadcrumb';

type DataProps = {
  commonComponents: {
    commonComponentsData: {
      letsTalkBanner: LetsTalkBannerType;
    };
  };
  offersEmployerBrandingPage: {
    template: {
      offersEmployerBrandingData: {
        bannerQuaternary: BannerPrimaryType;
        employerBrandingDescription: TextBannerCardType;
        employerBrandingWhatIs: TextBannerWithImageType;
        employerBrandingWhen: TextBannerWithImageType;
        advantagesSection: TextBannerCardType;
        employerBrandingServices: IndustriesServicesType;
        employerBrandingTeam: ManagerSectionType;
        employerBrandingCaseStudies: CaseStudiesType;
        otherServicesBlock: TextBannerCardType;
      };
    };
  };
};

export const OffersEmployerBrandingPage = ({
  pageContext,
  data: {
    offersEmployerBrandingPage: {
      template: {
        offersEmployerBrandingData: {
          bannerQuaternary: { heading, image, text },
          employerBrandingDescription,
          employerBrandingWhatIs,
          employerBrandingWhen,
          advantagesSection,
          employerBrandingServices,
          employerBrandingTeam,
          employerBrandingCaseStudies,
          otherServicesBlock,
        },
      },
    },
    commonComponents: {
      commonComponentsData: { letsTalkBanner },
    },
  },
}: PageProps<DataProps, PageContextType>) => (
  <Layout {...{ pageContext }}>
    <HeadTitleDescriptionImage
      titleTop={heading}
      subtitleTop={text}
      showDots
      positionTitle="left"
      image={image}
    >
      <Breadcrumb {...{ pageContext }} />
    </HeadTitleDescriptionImage>
    <TextBannerCardSection {...employerBrandingDescription} textColumn />
    <OurMissionSection firstSection={employerBrandingWhatIs} secondSection={employerBrandingWhen} />
    <TextBannerCardSection {...advantagesSection} bgColor={theme.colors.gray0}>
      {advantagesSection.cardList.map((item) => (
        <BannerCard
          {...item}
          bgColor="white"
          padding={theme.gap.md}
          mobilePadding={theme.gap.sm}
          key={item.text}
        />
      ))}
    </TextBannerCardSection>
    <IndustriesServices {...employerBrandingServices} bgColor="white" />
    <ManagerSection {...employerBrandingTeam} />
    <CaseStudies {...employerBrandingCaseStudies} />
    <LetsTalkBanner {...letsTalkBanner} margin={`${theme.gap.xl} auto ${theme.gap.xl}`} />
    <TextBannerCardSection {...otherServicesBlock} textColumn>
      {otherServicesBlock && otherServicesBlock.cardList
        ? otherServicesBlock.cardList.map((item) => <BannerCard {...item} key={item.text} />)
        : null}
    </TextBannerCardSection>
  </Layout>
);

export const query = graphql`
  query OffersEmployerBrandingPage($id: String, $commonComponentsSlug: String) {
    offersEmployerBrandingPage: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_OffersEmployerBranding {
          offersEmployerBrandingData {
            bannerQuaternary {
              heading
              text
              image {
                ...WpImageFragment
              }
            }
            employerBrandingDescription {
              text
              heading
            }
            employerBrandingWhatIs {
              heading
              text: description
              image {
                ...WpImageFragment
              }
              bulletsList {
                text
              }
            }
            employerBrandingWhen {
              heading
              text: description
              image {
                ...WpImageFragment
              }
              bulletsList {
                text
              }
              linkButton {
                icon {
                  name
                }
                label
                link {
                  ariaLabel
                  internal: wpInternal {
                    ... on WpPage {
                      uri
                    }
                  }
                  external {
                    target
                    url
                  }
                }
              }
            }
            advantagesSection {
              heading
              subTitle
              text
              cardList: advantagesList {
                text
                heading
                icon {
                  name
                }
              }
            }
            employerBrandingServices {
              heading
              subHeading
              text
              servicesList {
                heading
                text
                linkButton {
                  icon {
                    name
                  }
                  label
                  link {
                    ariaLabel
                    internal: wpInternal {
                      ... on WpPage {
                        uri
                      }
                    }
                    external {
                      target
                      url
                    }
                  }
                }
              }
            }
            employerBrandingTeam {
              backgroundImage {
                altText
                localFile {
                  url
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              businessCards {
                businessCard {
                  contactList {
                    type
                    link {
                      internal: wpInternal {
                        ... on WpPage {
                          uri
                        }
                      }
                      external {
                        target
                        url
                      }
                      ariaLabel
                    }
                  }
                  fullName
                  image {
                    altText
                    localFile {
                      url
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                  text
                  position
                  positionTitle
                }
              }
            }
            employerBrandingCaseStudies {
              heading
              subTitle
              caseStudyPost {
                ... on WpKnow_how {
                  id
                  title
                  link
                  date(formatString: "DD.MM.YYYY", locale: "PL")
                  categories {
                    nodes {
                      name
                    }
                  }
                  tags {
                    nodes {
                      name
                    }
                  }
                  featuredImage {
                    node {
                      ...WpImageFragment
                    }
                  }
                }
              }
            }
            otherServicesBlock {
              subTitle
              heading
              text
              cardList: servicesList {
                heading
                text
                icon {
                  name
                }
                linkButton {
                  label
                  link {
                    ariaLabel
                    internal: wpInternal {
                      ... on WpPage {
                        id
                        uri
                      }
                    }
                    external {
                      target
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    commonComponents: wpPage(slug: { eq: $commonComponentsSlug }) {
      commonComponentsData {
        letsTalkBanner {
          heading
          description
          linkButton {
            label
            icon {
              name
            }
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
              external {
                target
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default OffersEmployerBrandingPage;
